import React, { useState } from "react"
import { graphql } from "gatsby"
import { Box, Container, Heading, Grid, Text, Flex } from "@theme-ui/components"
import { getPagePath } from "../utils/path"
import { useFavicon } from "../hooks/useFavicon"
import Header from "../layouts/Header"
import Footer from "../layouts/Footer"
import NewsletterForm from "../components/NewsletterForm"
import MobileNav from "../layouts/Navbar/Mobile"
import Logo from "../assets/images/logo.inline.svg"
import { InboundLink } from "../utils/link"
import { getHomePath } from "../utils/path"
import { i18nContext } from "../context/i18nContext"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import ErrorIcon from "../assets/images/XXO.inline.svg"

const ComingSoon = () => {
  const currentPastMonth = true //isMonth(page.title.split(" ")[0]) 

  return (
    <i18nContext.Provider value={["it"]}>
      <LanguageSwitcherContext.Provider value={{ activeLocale: "it" }}>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "100vh",
          }}
        >
          <Box
            sx={{
              position: ["relative"],
              zIndex: 1,
              top: 0,
              left: 0,
              width: "100%",
              borderBottom: "1px solid",
              borderColor: "dark",
            }}
          >
            <Box>
              <Box as="nav" sx={{ width: "100%" }}>
                <Container sx={{ width: "100%", py: [3, 4] }}>
                  <Grid
                    columns={[
                      "1fr minmax(250px,300px) 1fr",
                      "1fr minmax(250px,300px) 1fr",
                      "1fr minmax(250px,300px) 1fr",
                      "1fr minmax(300px,400px) 1fr",
                    ]}
                  >
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                          margin: 0,
                          listStyle: "none",
                          alignItems: "center",
                          paddingX: 2,
                          color: currentPastMonth ? "light" : "dark",
                          justifyContent: "flex-end",
                        }}
                        as="ul"
                      ></Box>
                      <Box
                        sx={{
                          position: "relative",
                          px: 2,
                          textAlign: "center",
                          paddingTop: 0,
                          a: {
                            textDecoration: "none",
                          },
                          "a:hover": {
                            color: "primary",
                          },
                        }}
                      >
                        <InboundLink
                          to={getHomePath("it")}
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            svg: {
                              pt: [1],
                              maxHeight: ["30px", "70px"],
                              width: "100%",
                            },
                          }}
                        >
                          <Logo />
                        </InboundLink>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          padding: 0,
                          margin: 0,
                          listStyle: "none",
                          alignItems: "center",
                          color: currentPastMonth ? "light" : "dark",
                          justifyContent: "flex-start",
                        }}
                        as="ul"
                      ></Box>
                    </>
                  </Grid>
                </Container>
              </Box>
            </Box>
          </Box>
          <Box>
            <Container sx={{ pt: ["60px", "60px", 0], maxWidth: "900px" }}>
              <Heading as="h1" variant="h2" sx={{ color: "text", mb:[5,5], mt:[0], textAlign:"center" }}>
                Non così veloce!
              </Heading>
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  pb: [4],
                }}
              >
                <Box
                  sx={{
                    svg: {
                      width: "100%",
                      height: "auto",
                    },
                  }}
                >
                  <ErrorIcon />
                </Box>
              </Box>
              <Heading as="p" variant="p" sx={{ fontSize: [4, 6, 8] }}>
                  L'innovazione corre ma tu stai bruciando le tappe.
                  Questo mese sarà disponibile al momento giusto; nel frattempo lasciati ispirare dai contenuti dei mesi scorsi. 
              </Heading>
            </Container>
          </Box>
          <NewsletterForm />
          <Footer />
        </Flex>
      </LanguageSwitcherContext.Provider>
    </i18nContext.Provider>
  )
}

export default ComingSoon
